function Releases() {
  return (
    <div>
      <div className="media">
        <a
          href="https://open.spotify.com/artist/5w1m1Gh79PLIOFKSW75hYs?si=ZkrmwG7KTiGGCmiwRYd8Uw"
          target="_blank"
          rel="noreferrer"
          className="image-link"
        >
          <img alt="First Contact" src="../releases/first_contact.png" />
        </a>
        <a
          href="https://open.spotify.com/album/1RsOxUFujiaNyjZnyt1J7B?si=BcRfIcyiStSqBWRZI_OK6g"
          target="_blank"
          rel="noreferrer"
          className="image-link"
        >
          <img alt="Duality" src="../releases/duality.png" />
        </a>
        <a
          href="https://open.spotify.com/album/1F5WjeWTFcrTi0AkhTBfTV?si=tqKxxr5GQD-tgg3f1yb_TA"
          target="_blank"
          rel="noreferrer"
          className="image-link"
        >
          <img alt="Pyramids" src="../releases/pyramids.png" />
        </a>
        <a
          href="https://open.spotify.com/album/3NshuqYqLkRCBdrmvadXo9?si=Mzkou2IQT1SVz6bvIh-bPw"
          target="_blank"
          rel="noreferrer"
          className="image-link"
        >
          <img alt="Tetraphobia" src="../releases/tetraphobia.png" />
        </a>
        <a
          href="https://open.spotify.com/album/266MLAZhf0LnRkpIBGsExe?si=WEKZVcrLR6GEqjp651EiiQ"
          target="_blank"
          rel="noreferrer"
          className="image-link"
        >
          <img alt="Everything..." src="../releases/everything.png" />
        </a>
        <a
          href="https://open.spotify.com/track/4hhmbzsKcuan7NkpZdDKZ5?si=6e4560f98bd64ee3"
          target="_blank"
          rel="noreferrer"
          className="image-link"
        >
          <img alt="Voyage Voyage" src="../releases/voyage_voyage.png" />
        </a>
      </div>
    </div>
  );
}

export default Releases;
