import React from "react";
import { Link } from "react-router-dom";

function Menu() {
  return (
    <div className="header">
      <nav>
        <ul>
          <li>
            <Link to="/" className="logo">
              Horacio
            </Link>
          </li>
          <li>
            <Link to="/releases">Releases</Link>
          </li>
          <li>
            <Link to="/videos">Videos</Link>
          </li>
          {/* <li>
            <Link to="https://store.horaciomusic.com/" target="_blank">
              Store
            </Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
}

export default Menu;
