import "./App.css";
import "./mobile.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Releases from "./Releases";
import Menu from "./Menu";
import Videos from "./Videos";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <div>
          <Menu />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/releases" element={<Releases />} />
            <Route exact path="/videos" element={<Videos />} />
          </Routes>
          <div className="footer">
            <h4>© Lambaol Music 2022-2023</h4>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
