function Home() {
  return (
    <div>
      <div className="intro">
        <h1>
          Combining deep house and melodic techno with vintage synth sounds
        </h1>
      </div>
      <div className="media">
        <a
          href="https://open.spotify.com/artist/5w1m1Gh79PLIOFKSW75hYs?si=ZkrmwG7KTiGGCmiwRYd8Uw"
          target="_blank"
          rel="noreferrer"
          className="media-link"
        >
          Spotify
        </a>
        <a
          href="https://music.apple.com/us/artist/horacio/1626319642"
          target="_blank"
          rel="noreferrer"
          className="media-link"
        >
          Apple
        </a>
        <a
          href="https://www.youtube.com/channel/UCe2q3jJZSpoFhOaQPNHs7TA"
          target="_blank"
          rel="noreferrer"
          className="media-link"
        >
          Youtube
        </a>
        <a
          href="https://www.deezer.com/us/album/322334437"
          target="_blank"
          rel="noreferrer"
          className="media-link"
        >
          Deezer
        </a>
        <a
          href="https://listen.tidal.com/artist/36190268"
          target="_blank"
          rel="noreferrer"
          className="media-link"
        >
          Tidal
        </a>
        <a
          href="https://www.pandora.com/artist/horacio/ARJjx9z4zc27rw2"
          target="_blank"
          rel="noreferrer"
          className="media-link"
        >
          Pandora
        </a>
      </div>
    </div>
  );
}

export default Home;
